import antd from 'ant-design-vue/es/locale-provider/zh_CN'
import momentCN from 'moment/locale/zh-cn'

const components = {
  antLocale: antd,
  momentName: 'zh-cn',
  momentLocale: momentCN
}

const locale = {
  'message': '-',
  'menu.home': '首页',
  'menu.dashboard': '仪表盘',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',

  'layouts.usermenu.dialog.title': '提示',
  'layouts.usermenu.dialog.content': '你确定要注销登录吗',

  // 交易管理
  'app.trade.transaction': '交易管理',
  'app.trade.transactionList': '交易查询',
  "app.trade.merchantOrderNo" : "商户订单号",
  "app.trade.settleDetails" : "财务流水",
  "app.service.merchantService" : "商户服务 ",
  "app.service.serviceInfo" : "服务信息",
  "app.service.security" : "安全中心",
  "app.agentpay.transaction" : "提币",
  "app.agentpay.StepForm" : "ERC20提币",
  "app.agentpay.AgentpayList" : "ERC20提币记录",
  "app.channel.channelList" : "通道管理",
  "app.withdraw.AgentpayList" : "明细查询",


}

export default {
  ...components,
  ...locale
}
